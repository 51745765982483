import { mCPF } from '../../../utils/Mask';

export class DadosSocString {
    dadosSoc = '';
    constructor(dadosSoc) {
        this.dadosSoc = dadosSoc;
    }

    buscaCpfTrab() {
        const regex = /"CPFTRAB":"(\d+)"/;
        const cpfNumerico = this.dadosSoc?.match(regex)?.[1];
        return mCPF(cpfNumerico);
    }

    buscaDataExame() {
        const regex = /"DTEXAME":"([^"]+)"/;
        const data = this.dadosSoc?.match(regex)?.[1];
        return data;
    }
}
