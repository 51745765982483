<template>
    <AppPanel :location="['Integração apollus']" subtitle="Integração apollus">
        <template #content>
            <div class="">
                <AppPaginatedGrid
                    ref="grid"
                    :service="service"
                    :actionItems="actionItems"
                    newPath="/integracao-apollus/new"
                    editPath="/integracao-apollus/"
                    @onToggleMenu="onToggleMenu"
                    :loadingEffect="false"
                    subtitle="Integração apollus"
                    tooltip="Sincronização com Apollus"
                    nomeTelaDoManual="integracao-apollus-list"
                >
                    <template #header>
                        <div class="grid">
                            <div class="col-6">
                                <Button
                                    :loading="loadingPendentes"
                                    label="Processar pendentes"
                                    icon="pi pi-check"
                                    class="p-button-success"
                                    style="margin: 0px 5px"
                                    @click="processarPendentes"
                                />
                                <Checkbox id="auto-load" v-model="autoload" :binary="true" />
                                <label for="auto-load">Auto load</label>
                            </div>
                        </div>
                    </template>

                    <template #columns>
                        <Column bodyClass="text-right" headerStyle="width: 10rem;">
                            <template #body="slotProps">
                                <Button
                                    :disabled="
                                        slotProps.data.status == 'FINALIZADA' ||
                                        slotProps.data.status == 'CANCELADA' ||
                                        slotProps.data.status == 'PROCESSANDO' ||
                                        !slotProps.data.status
                                    "
                                    label="Processar"
                                    icon="pi pi-check"
                                    class="p-button-success"
                                    style="margin: 0px 5px"
                                    @click="processar($event, slotProps.data)"
                                />
                            </template>
                        </Column>
                        <Column field="dadosSoc" header="CPF">
                            <template #body="{ data }">
                                <span class="mr-2">
                                    {{ obterDadosSoc(data.dadosSoc).cpf }}
                                </span>
                            </template>
                        </Column>
                        <Column :sortable="true" field="id" sortField="integracao_apollus.id" header="Código"></Column>
                        <Column :sortable="true" field="cliente.name" header="Cliente"></Column>
                        <Column :sortable="true" field="status" header="Status"></Column>
                        <Column :sortable="true" field="erro" header="Mensagem"></Column>
                        <Column :sortable="true" field="codigoFichaSoc" header="Código Ficha"></Column>
                        <Column :sortable="true" field="codigoAsoApollus" header="Código apollus"></Column>
                        <Column field="keyAlteracao" header="Exame clinico">
                            <template #body="{ data }">
                                <span class="mr-2">
                                    {{ obterDadosSoc(data.dadosSoc).dataExame }}
                                </span>
                            </template></Column
                        >
                        <Column :sortable="true" field="createdAt" header="Criado em">
                            <template #body="{ data }">
                                <div class="flex flex-column mr-2">
                                    {{ $filters.formatDateOnly(data.createdAt) }}
                                </div>
                            </template>
                        </Column>
                    </template>
                </AppPaginatedGrid>
            </div>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getClientBase } from '../../services/http';
import { DadosSocString } from './utils/dados-soc-string';

export default {
    data() {
        return {
            loadingPendentes: false,
            autoRefresh: false,
            autoload: false,
            service: null,
            record: {},
            totais: {},
            actionItems: [
                {
                    label: 'Editar',
                    icon: 'pi pi-desktop',
                    command: () => this.editRecord(this.record)
                },
                {
                    label: 'Remover',
                    icon: 'pi pi-times-circle',
                    command: () => this.remover(this.record)
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/integracao-apollus');
        setTimeout(() => {
            this.autoLoad();
        }, 3000);
    },
    watch: {
        autoload() {
            this.autoLoad();
        }
    },
    methods: {
        async autoLoad() {
            if (!this?.$refs?.grid) {
                return;
            }

            if (!this.autoload) {
                return;
            }

            await this.$refs.grid.load();
            setTimeout(() => {
                this?.autoLoad && this.autoLoad();
            }, 3000);
        },
        async processar($event, data) {
            data.status = '';
            const retorno = await getClientBase().post('/integracao-apollus/reprocessar/' + data.id);
            data.status = retorno.data.status;
            data.erro = retorno.data.erro;
            data.codigoFichaSoc = retorno.data.codigoFichaSoc;
            data.codigoAsoApollus = retorno.data.codigoAsoApollus;
            this?.$refs?.grid && this.$refs.grid.load();
        },
        async processarPendentes() {
            this.loadingPendentes = true;
            await getClientBase().post('/integracao-apollus/reprocessar-pendentes/');
            this.loadingPendentes = false;
            this?.$refs?.grid && this.$refs.grid.load();
        },
        async remover() {
            try {
                this.record.status = '';
                await getClientBase().delete('/integracao-apollus/' + this.record.id);
                this.$toast.add({ severity: 'success', summary: 'Importação cancelada com sucesso', life: 3000 });
            } catch (error) {
                const message = error.response?.data?.message || error.message;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao cancelar: ' + message, life: 3000 });
            }
            this?.$refs?.grid && this.$refs.grid.load();
        },
        onToggleMenu(event, data) {
            this.record = data;
        },
        editRecord(record) {
            this.$router.push('/integracao-apollus/' + record.id);
        },
        obterDadosSoc(dadosSoc) {
            return {
                cpf: new DadosSocString(dadosSoc).buscaCpfTrab(),
                dataExame: new DadosSocString(dadosSoc).buscaDataExame()
            };
        }
    }
};
</script>
